import React, { useState } from 'react';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';
import events from '../api/events';
function ModalAlert() {
    let [show, setShow] = useState(false);
    let [title, setTitle] = useState('d');
    let [message, setMessage] = useState('d');
    events.registerClientCallback('messageAlert', (data, cb) => {
        setTitle(data.title);
        setMessage(data.message);
        setShow(true);
        cb();
    });

    return (
        <Modal
            show={show} onHide={() => {
                setShow(false)
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <p>
                    {message}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='warning' onClick={() => {
                    setShow(false)
                }} >Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

let modalInputCallback: CallableFunction
function ModalInput() {
    let [show, setShow] = useState(false);
    let [title, setTitle] = useState('d');
    //let [message, setMessage] = useState({ __html: ' <h1>hi</h1>' });
    let [message, setMessage] = useState('');
    let [Type, setType] = useState('text');

    events.registerClientCallback('modalInput', (data, cb) => {
        setTitle(data.title);
        setMessage(data.message);
        setShow(true);
        setType(data.InputType)
        modalInputCallback = cb
    });

    return (
        <Modal
            show={show} onHide={() => {
                setShow(false)
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <form onSubmit={(event) => {

                event.preventDefault();

                let target = event.target as typeof event.target & {
                    input: { value: string }
                }
                if (modalInputCallback) {
                    modalInputCallback(target.input.value)
                    modalInputCallback = () => (false);
                }
                event.currentTarget.reset();
            }}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">

                        <Form.Control name='input' type={Type} autoComplete="false" placeholder={message} />
                    </Form.Group>



                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ background: '#004080', borderColor: 'white !important' }} onClick={() => {
                        setShow(false)
                    }} >Cancelar</Button>

                    <Button type='submit' style={{ marginRight: '0%' }} variant='success' onClick={() => {
                        setShow(false)

                    }} >Aceptar</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}


let modalQuestionCallback: CallableFunction
function ModalQuestion() {
    let [show, setShow] = useState(false);
    let [title, setTitle] = useState('d');
    //let [message, setMessage] = useState({ __html: ' <h1>hi</h1>' });
    let [message, setMessage] = useState('');
    events.registerClientCallback('modalQuestion', (data, cb) => {
        setTitle(data.title);
        setMessage(data.message);
        setShow(true);
        modalQuestionCallback = cb
    });

    return (
        <div onKeyDown={(event) => {
            if (event.key === 'Enter') {
                setShow(false)

                if (modalQuestionCallback) {
                    modalQuestionCallback(true)
                    modalQuestionCallback = () => (false);
                }
            }
        }}>
        <Modal
            show={show} onHide={() => {
                setShow(false)
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <p>
                    {message}
                </p>

            </Modal.Body>
            <Modal.Footer>

                    <Button style={{ background: '#004080', borderColor: 'white !important' }} onClick={() => {
                        setShow(false);

                        if (modalQuestionCallback) {
                            modalQuestionCallback(false)
                            modalQuestionCallback = () => (false);
                        }
                    }} >Cancelar</Button>

                    <Button style={{ marginRight: '0%' }}  variant='success' onClick={() => {
                        setShow(false)

                        if (modalQuestionCallback) {
                            modalQuestionCallback(true)
                            modalQuestionCallback = () => (false);
                        }
                    }} >Aceptar</Button>
            
            </Modal.Footer>

        </Modal>
        </div>
    )
}



let modalQuestionHtmlCallback: CallableFunction
function ModalQuestionHtml() {
    let [show, setShow] = useState(false);
    let [title, setTitle] = useState('d');
    let [message, setMessage] = useState({ "__html": '' });
    //let [message, setMessage] = useState('');
    events.registerClientCallback('modalQuestionHtml', (data, cb) => {
        setTitle(data.title);
        setMessage({ "__html": data.message });
        setShow(true);
        modalQuestionHtmlCallback = cb
    });

    return (
        <div onKeyDown={(event) => {
            if (event.key === 'Enter') {
                setShow(false)

                if (modalQuestionHtmlCallback) {
                    modalQuestionHtmlCallback(true)
                    modalQuestionHtmlCallback = () => (false);
                }
            }
        }}>
        <Modal 
            show={show} onHide={() => {
                setShow(false)
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered

            
        >

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <p dangerouslySetInnerHTML={message}>

                </p>

            </Modal.Body>

                <input type="text" defaultValue='test' hidden />
                <Modal.Footer>

                    <Button style={{ background: '#004080', borderColor: 'white !important' }} onClick={() => {
                        setShow(false);

                        if (modalQuestionHtmlCallback) {
                            modalQuestionHtmlCallback(false)
                            modalQuestionHtmlCallback = () => (false);
                        }
                    }} >Cancelar</Button>

                    <Button style={{ marginRight: '0%' }} variant='success' onClick={() => {
                        setShow(false)

                        if (modalQuestionHtmlCallback) {
                            modalQuestionHtmlCallback(true)
                            modalQuestionHtmlCallback = () => (false);
                        }
                    }}>Aceptar</Button>

                </Modal.Footer>

        </Modal>
        </div>
    )
}
let modalSelector: CallableFunction
function ModalSelector() {
    let [show, setShow] = useState(false);
    let [title, setTitle] = useState('d');
    //let [message, setMessage] = useState({ __html: ' <h1>hi</h1>' });
    let [message, setMessage] = useState([] as Array<{ id: any, name: string }>);
    events.registerClientCallback('modalSelector', (data, cb) => {
        console.log(data);

        setTitle(data.title);
        setMessage(data.options);
        setShow(true);
        modalSelector = cb
    });

    return (
        <Modal
            show={show} onHide={() => {
                setShow(false)
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>

                    {
                        message.map((current, index) => (

                            <ListGroup.Item action variant="dark" key={index} onClick={() => {
                                modalSelector(current.id);
                                setShow(false);
                            }} >
                                {current.name}
                            </ListGroup.Item>

                        ))
                    }

                </ListGroup>

            </Modal.Body>
            <Modal.Footer>
                <Button style={{ background: '#004080', borderColor: 'white !important' }} onClick={() => {
                    setShow(false);

                    if (modalSelector) {
                        modalSelector(false)
                        modalSelector = () => (false);
                    }
                }} >Cancelar</Button>

            </Modal.Footer>

        </Modal>
    )
}
export { ModalAlert, ModalInput, ModalQuestion, ModalSelector, ModalQuestionHtml }