import { useState } from 'react';
import { Container, Card, Col, Row, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import events from '../api/events';

function Login() {
   let [user, setUser] =  useState('')
   let [password, setPassword] =  useState('')
   let redirect = useNavigate();
    return (
        <>

            <Container className='bodyContainerCentered' >
                <Row style={{ justifyContent: 'center', display: 'flex', marginTop: 'auto', marginBottom: 'auto' }}>
                    <Col xl={6} sm={12}>
                        <form onSubmit={(event) => {
                                        event.preventDefault()
                                        events.triggerServerCallback('login', {user, password}, (response) => {
                                            console.log(response);

                                            if (response) {
                                                
                                                events.setSessionHash(response)
                                                redirect('/main')
                                            }else{

                                            }
                                            
                                        });
                                        event.currentTarget.reset()
                                        setPassword('')
                                        setUser('')

                                    }}>
                        <Card bg={'ligth'}>
                            <Card.Header style={{ background: 'rgba(4, 23, 105, 0.966)', color: 'white' }}  >Login</Card.Header>
                            <Card.Body  style={{ background: 'rgb(180, 180, 180)'}}>
                                <div style={{display:'flex', 'justifyContent': 'center'}}>

                                    <Container style={{width: '50%', color: 'black'}}>
                                        
                                            <Form.Group className="mb-3">
                                                
                                                <Form.Control autoComplete="false" onChange={(event) => {
                                                    setUser(event.target.value)
                                                }} placeholder="Usuario" />
                                            </Form.Group>

                                            <Form.Group className="mb-2">
                                                
                                                <Form.Control autoComplete="new-password" type='password' onChange={(event) => {
                                                    setPassword(event.target.value)
                                                }} placeholder="Contraseña" />
                                            </Form.Group>
                                            
                                    </Container>

                                </div>
                            </Card.Body>
                            <Card.Footer style={{ background: 'rgb(218, 214, 213)' }}>
                                    <div style={{display:'flex', 'justifyContent': 'end'}}>
                                    <Button type='submit' variant='primary'>Ingresar</Button>
                                    </div>
                            </Card.Footer>
                        </Card>
                        </form>
                    </Col>
                </Row>





            </Container>
        </>
    )
}

export default Login;