import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';

import 'bootstrap/dist/css/bootstrap.css';

import { BrowserRouter } from 'react-router-dom';

import {Container} from 'reactstrap'
import NavBar from './router/navBar';
require('./api/events')
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Container style={{'overflow': 'auto', width: '100vw', height: '100vh'}}>

      <NavBar />
    </Container>
    
  </BrowserRouter>
);
