import { v4 as uuid, } from 'uuid';
function socket(cb: (message: string, send: CallableFunction, hash: string) => void) {
    let device = localStorage.getItem('device');

    if (!device) {
        device = uuid();
        localStorage.setItem('device', device as any);
    }

    let timer: any = 0;
    let timeouts: number = 0
    let ws = new WebSocket(`wss://${window.location.hostname}/api/socket`);

    registerEvents();

    setInterval(() => {
        if (timeouts > 3 || ws.readyState > 1) {
            reconnect();
        }
    }, 4000);

    function reconnect() {
        timeouts = 0;
        clearInterval(timer);
        ws.removeEventListener('close', onClose);
        ws.removeEventListener('error', onError);
        ws.removeEventListener('message', onMessage);

        ws = new WebSocket(`wss://${window.location.hostname}/api/socket`);

        registerEvents();
    }

    function registerEvents() {
        ws.onopen = onOpen

        ws.onclose = onClose

        ws.onmessage = onMessage

        ws.onerror = onError
    }


    function onMessage(msg: MessageEvent) {

        if (msg.data === 'PONG') {
            if (timeouts > 0) {

                timeouts = timeouts - 1;

            }

        } else {
            cb(msg.data, send, device as string)
        }


    }

    function send(data: any) {
        let interval = setInterval(() => {

            if (ws.readyState === 1 && timeouts < 2) {
                ws.send(JSON.stringify(data));
                clearInterval(interval);
            }

        }, 10);
    }

    function onClose() {
        console.log('closed socket');

    }

    function onError(err: any) {
        console.log(err);

    }

    function onOpen() {
        ws.send(JSON.stringify({type: 'ping', sourceDevice:device}));
        timer = setInterval(() => {
            if (ws.readyState === 1) {
                ws.send(JSON.stringify({type: 'ping', sourceDevice:device}));
                timeouts = timeouts + 1
            }
        }, 1000)
    }


    return send
}
export default socket