
import socket from "./socket"
interface eventHeaderTx {
    name: string,
    args: string,
    sourceDevice: string|null,
    userHash: string| null,
    type: 'serverCallback' | 'clientReturnees'
    returnAlias?: string
}

interface eventHeaderRx {
    name: string,
    data: any,
    type: 'clientCallbackTrigger'| 'serverCallbackResponse',
    returnAlias?: string
    
}


let userHash = localStorage.getItem('userHash')

let device = localStorage.getItem('device');
if(!userHash){
    userHash = '';
}

let callbacks: {[id:string]:{cb: (data:any, callback:CallableFunction)=> void}  } = {};

let returnCallback : {[id:string]: CallableFunction} = {}

let send = socket(( message, send, device ) => {

    try {
        let headerMessage:eventHeaderRx = JSON.parse(message);

        if (headerMessage.type === 'clientCallbackTrigger') {
            callbacks[headerMessage.name].cb(JSON.parse(headerMessage.data) ,(dataReturn?:any) => {

                let eventHeaderTx: eventHeaderTx = {args: dataReturn, type: 'clientReturnees', name: headerMessage.returnAlias as string,sourceDevice: device, userHash }
                send(eventHeaderTx);
            })
        } else if (headerMessage.type === 'serverCallbackResponse') {

            
            if (returnCallback[headerMessage.name]) {
                returnCallback[headerMessage.name](headerMessage.data)

                
            }else{
                console.log(headerMessage.name ,'ya no es valido o no existe');

            }
        }

    }catch(err){
        console.log('incoming malformed data', message, err);

        
    }

});

let events = {

    registerClientCallback(name:string, cb: (data:any, cb:CallableFunction) => void ) {
        callbacks[name]= {cb}
    },


    async triggerServerCallback(name:string, args:any, res: (dataResponse:any)=>any ){
        let returnAlias = name+Date.now() + Math.floor(Math.random() * 10000)
        let eventHeaderTx:eventHeaderTx={
            args: args, name, sourceDevice:device, type: 'serverCallback',  userHash: userHash,returnAlias: returnAlias
        }

        returnCallback[returnAlias] = res

        send(eventHeaderTx)


    },

    setSessionHash(hash:string) {
        userHash = hash
        localStorage.setItem('userHash', hash);
    },
    
    doLogon (onRedirect:()=>void){

        events.registerClientCallback('doLogin', (data, cb) => {
            cb();
            onRedirect();
        });
    },
}


export default events