import { Container, Row, Col, Card, Button, Table } from 'react-bootstrap'
import { Suspense, useEffect, useState } from 'react';
import NavSystem from '../components/nav';
import { api } from '../api/api';
import LoadSpinner from '../components/spiner';
import { CiSettings } from "react-icons/ci";

interface User {
    _id: any,
    user: string,
    password: string,
    displayName: string,
    salt: string,
    range: number
}
function Users() {

    let [visits, setVisits] = useState({} as { [id: string]: User })


    useEffect(() => {

        api.triggerServerCallback('getUsers', false, (data) => {
            setVisits(data)
        });


    }, []);


    return (
        <>

            <NavSystem />

            <Container className='bodyContainer'>
                <Row style={{ justifyContent: 'center', marginTop: '7%', }}>
                    <Col sm={12} lg={12} >

                        <Card>

                            <Card.Header style={{ background: 'rgba(4, 23, 105, 0.966)', color: 'white' }}>Usuarios</Card.Header>
                            <Card.Body style={{ background: 'rgb(180, 180, 180)' }} >
                                <Container style={{ width: '90%', color: 'black' }}>


                                    <Table striped="columns">
                                        <thead>
                                            <tr>
                                                <th>User Name</th>
                                                <th>Nombre</th>
                                                <th>Rango</th>
                                                <th>Contraseña</th>
                                                <th>Eliminar</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <Suspense fallback={<LoadSpinner />}>

                                                {
                                                    Object.keys(visits).map((key, index) => (

                                                        <tr key={index}>

                                                            <td>{visits[key].user}</td>
                                                            <td>{visits[key].displayName}</td>
                                                            <td>{visits[key].range ? 'Administrador' : 'Usuario'} <CiSettings onClick={() => {
                                                                api.triggerServerCallback('changeUserRange', {user: key}, () => {
                                                                    api.triggerServerCallback('getUsers', false, (data) => {
                                                                        setVisits(data)
                                                                    });
                                                                });
                                                            }} /> </td>
                                                            <td>
                                                                <Button variant='primary' onClick={() => {
                                                                    api.triggerServerCallback('setUserPass', { user: key }, () => {
                                                                        api.triggerServerCallback('getUsers', false, (data) => {
                                                                            setVisits(data)
                                                                        });
                                                                    });
                                                                }} >Cambiar</Button>
                                                            </td>

                                                            <td>
                                                                <Button variant='danger' onClick={() => {
                                                                    api.triggerServerCallback('deleteUser', { user: key }, () => {
                                                                        api.triggerServerCallback('getUsers', false, (data) => {
                                                                            setVisits(data)
                                                                        });
                                                                    });
                                                                }} >Eliminar</Button>

                                                            </td>

                                                        </tr>
                                                    )
                                                    )
                                                }

                                            </Suspense>



                                        </tbody>
                                    </Table>



                                </Container>

                            </Card.Body>
                            <Card.Footer style={{ background: 'rgb(218, 214, 213)' }}>
                                <div style={{ display: 'flex', 'justifyContent': 'end' }}>

                                    <Button variant='success' onClick={() => {
                                        api.triggerServerCallback('createUser', false, () => {
                                            api.triggerServerCallback('getUsers', false, (data) => {
                                                setVisits(data)
                                            });
                                        });
                                    }}>Registrar nuevo usuario</Button>



                                </div>


                            </Card.Footer>
                        </Card>


                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Users;
