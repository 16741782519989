import { Container, Row, Col, Card, Button, Table, ListGroup } from 'react-bootstrap'
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { api } from '../api/api';
import LoadSpinner from '../components/spiner';
import { downloadExcel } from 'react-export-table-to-excel';

function HistoryOff() {


    let [visits, setVisits] = useState(api.data.getApp().visitors)


    useEffect(() => {

        api.triggerServerCallback('getVisitOff', false, (data) => {
            setVisits(data)
        });

        api.scripting.pauseSync(true);

        let interval = setInterval(() => {
            api.triggerServerCallback('getVisitOff', false, (data) => {
                setVisits(data)
            });
        }, 60000);

        return () => {
            clearInterval(interval)
        }

    }, []);


    return (
        <>

            <Container className='bodyContainerOff'>
                <Row style={{ justifyContent: 'center', marginBottom: '0%' }}>
                    <Col sm={12} lg={12} >

                        <Card style={{}}>

                            <Card.Header style={{ background: 'rgba(4, 23, 105, 0.966)', color: 'white' }}>Histórico</Card.Header>
                            <Card.Body style={{ background: 'rgb(180, 180, 180)' }} >
                                <Container style={{ width: '90%', color: 'black' }}>


                                    <Table striped="columns">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Nombre</th>
                                                <th>DNI/ID</th>
                                                <th>Inicio</th>
                                                <th>Fin</th>
                                                <th>Agente</th>
                                                <th>Razón/Motivo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <Suspense fallback={<LoadSpinner />}>

                                                {
                                                    Object.keys(visits).map((key, index) => (

                                                        <tr key={index}>
                                                            <td>{key}</td>
                                                            <td>{visits[Number(key)].person.Name}</td>
                                                            <td>{visits[Number(key)].person.dni}</td>
                                                            <td>{new Date(visits[Number(key)].joinDate).toLocaleString('es-PE', { timeZone: 'America/Lima' })}</td>
                                                            <td>{visits[Number(key)].exitDate ? new Date(visits[Number(key)].exitDate).toLocaleString('es-PE', { timeZone: 'America/Lima' }) : 'En progreso'}</td>
                                                            <td>{visits[Number(key)].agent}</td>
                                                            <td>{visits[Number(key)].reason}</td>
                                                        </tr>
                                                    )
                                                    )
                                                }

                                            </Suspense>



                                        </tbody>
                                    </Table>



                                </Container>

                            </Card.Body>
                            <Card.Footer style={{ background: 'rgb(218, 214, 213)' }}>
                                <div style={{ display: 'flex', 'justifyContent': 'end' }}>

                                    
                                    <Button variant='primary' style={{ marginRight: '2%' }} onClick={() => {
                                        let table: Array<{ id: string, nombre: string, dni: string, inicio: string, agente: string, oficina: string, salida: string, razon: string, coment: string }> = []

                                        for (let key in visits) {
                                            let currentVisit = visits[key];
                                            table.push({ id: key, nombre: currentVisit.person.Name, dni: currentVisit.dni.toString(), razon: currentVisit.reason, agente: currentVisit.agent, oficina: currentVisit.building, inicio: new Date(currentVisit.joinDate).toLocaleString('es-PE', { timeZone: 'America/Lima' }), salida: currentVisit.exitDate ? new Date(visits[Number(key)].exitDate).toLocaleString('es-PE', { timeZone: 'America/Lima' }) : 'En progreso', coment: currentVisit.comment })
                                        }

                                        downloadExcel({
                                            fileName: "visitas.xls",
                                            sheet: "Consolidado de visitas",
                                            tablePayload: {
                                                header: ["id", "Nombres", "DNI", "Razon", "Agente", "Torre", "Inicio", "Salida", "Comentario"],

                                                body: table
                                            }
                                        });

                                    }}>Exportar a hoja de datos XLS</Button>
                                    <Button variant='success' onClick={() => {
                                        api.triggerServerCallback('getVisitHist', false, (data) => {
                                            setVisits(data)
                                        });
                                    }}>Actualizar</Button>



                                </div>


                            </Card.Footer>
                        </Card>


                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default HistoryOff;
