import { Routes, Route, useNavigate } from 'react-router-dom';
import Main from "../pages/main";
import Footer from "../components/footer";
import Login from "../pages/login";
import { ModalAlert, ModalInput, ModalQuestion, ModalSelector,ModalQuestionHtml } from "../components/modal";
import { api } from "../api/api";
import Buildings from "../pages/buildings";
import History from "../pages/history";
import RunningHistory from "../pages/running";
import Users from "../pages/users";
import Vip from '../pages/vip';
import HistoryOff from '../pages/historyOff';


function NavBar() {
    let redirect = useNavigate();
    api.doLogon(() => {
        redirect('/login')
    });


    return (


        <>
            <ModalInput />
            <ModalAlert />
            <ModalQuestion/>
            <ModalSelector/>
            <ModalQuestionHtml/>
            <Routes>

                <Route path="/main" element={<Main />} />
                <Route path="/buildings" element={<Buildings />} />
                <Route path="/history" element={<History/>} />
                <Route path="/runninghistory" element={<RunningHistory/>}/>
                <Route path="/historyoff" element={<HistoryOff/>}/>       
                <Route path="/login" element={<Login />} />
                <Route path="/users" element={<Users />} />
                <Route path="/vip" element={<Vip />} />
                <Route path="*" element={<Login />} />
            </Routes>

            <Footer />

        </>
    )

}

export default NavBar