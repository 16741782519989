
let proxyHandler = < T>(path: string[] = [], updated?: () => void) => ({
    
    get: (target: T, key: keyof T): any => {
        if (key == 'isProxy') return true;
        if (typeof target[key] === 'object' && target[key] != null) {


            if (updated) {
                return new Proxy(
                    target[key],
                    proxyHandler<any>([...path, key as string], updated)
                );
            } else {
                return new Proxy(
                    target[key],
                    proxyHandler<any>([...path, key as string])
                );

            }


        }
        return target[key];
    },
    set: (target: T, key: keyof T, value: any) => {
        //console.log(`Setting ${[...path, key]} to: `, value);
        if (updated) {
            updated();
        } 

        target[key] = value;

        return true;
    }
});
export default proxyHandler