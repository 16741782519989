import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap'
import { useState } from 'react';
import NavSystem from '../components/nav';
import { api } from '../api/api';
import LoadSpinner from '../components/spiner';
function Main() {
    return (
        <>
            <NavSystem />
            <Container className='bodyContainer'>
                <Row style={{ justifyContent: 'center', marginTop: '7%' }}>
                    <Col sm={12} lg={5} >

                        <Card  >
                            <form autoComplete="off" onSubmit={(event) => {
                                event.preventDefault();

                                let target = api.scripting.parseForm(event);

                                api.triggerServerCallback('queryDni', target.input.value, (res) => {
                                    target.reset();
                                });
                            }}>
                                <Card.Header style={{ background: 'rgba(4, 23, 105, 0.966)', color: 'white' }}>Visitas</Card.Header>
                                <Card.Body style={{ background: 'rgb(180, 180, 180)' }} >
                                    <Container style={{ width: '50%', color: 'black' }}>

                                        <Form.Group className="mb-3">

                                            <Form.Control name='input' autoFocus type='number' autoComplete="off" 
                                             placeholder="DNI/IDENTIFICADOR" />
                                        </Form.Group>

                                    </Container>

                                </Card.Body>
                                <Card.Footer style={{ background: 'rgb(218, 214, 213)' }}>
                                    <div style={{ display: 'flex', 'justifyContent': 'end' }}>
                                        <Button type='submit'  variant='primary'>Consultar</Button>
                                    </div>
                                </Card.Footer>
                            </form>
                        </Card>


                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Main;
