import { Container } from "react-bootstrap"

function Footer() {
    
    return (
        <>
        <div id="footerContainer">
            <a>Miracle System © 2023 Customizado para  MUNICIPALIDAD PROVINCIAL GENERAL SANCHEZ CERRO</a>
        </div>
        </>
    )
}

export default Footer