import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { api, User } from '../api/api';
function NavSystem() {


    let [user, setUser] = useState({} as User);


    useEffect(() => {

        api.UserData((userData) => {
            setUser(userData)
        });

        api.scripting.pauseSync(false);
    }, [])
    let redirect = useNavigate();
    return (
        <>
            <Navbar collapseOnSelect expand="lg" style={{ width: '90%', height: '10%' }}>
                <Container >
                    <Navbar.Brand>DashBoard</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" style={{ background: 'rgb(233, 231, 231)', borderRadius: '7px', zIndex: '' }}>
                        <Nav className="me-auto" style={{ padding: '7px', color: 'white' }}>
                            <Nav.Link as={NavLink} end to="/main">Principal</Nav.Link>
                            <Nav.Link as={NavLink} to='/runninghistory'>Visitas en progreso</Nav.Link>
                            <Nav.Link as={NavLink} to='/history'>Historial de visitas</Nav.Link>
                            {user.range >= 1 &&
                                <>
                                    <Nav.Link as={NavLink} to='/users'>Usuarios</Nav.Link>
                                    <Nav.Link as={NavLink} to='/vip'>Vip</Nav.Link>
                                    <Nav.Link as={NavLink} to='/buildings'>Ubicaciones</Nav.Link>
                                </>

                            }
                            {/*
                                        <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                            <NavDropdown.Item href="#action/3.2">
                                                Another action
                                            </NavDropdown.Item>
                                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item href="#action/3.4">
                                                Separated link
                                            </NavDropdown.Item>
                                            </NavDropdown>*/}
                        </Nav>
                        {
                            <Nav style={{ padding: '7px' }}>
                                <Nav.Link >{user.displayName}</Nav.Link>
                                <Nav.Link onClick={() => {
                                    api.triggerServerCallback('terminateSession', false, (res) => {
                                        redirect('/login');
                                    });
                                    
                                }}>Salir</Nav.Link>
                            </Nav>}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default NavSystem