import { Container, Row, Col, Card, Button, Form, ListGroup } from 'react-bootstrap'
import React, { lazy, Suspense, useState } from 'react';
import NavSystem from '../components/nav';
import { api } from '../api/api';
import LoadSpinner from '../components/spiner';
function Buildings() {

   let [buildings, setBuilding] = useState(api.data.getApp().buildings)

   api.data.onUpdate(() => {
        setBuilding(api.data.getApp().buildings);
   });
    
    return (
        <>

            <NavSystem />
            
            <Container className='bodyContainer'>
                <Row style={{ justifyContent: 'center', marginTop: '7%' }}>
                    <Col sm={12} lg={5} >
                    
                        <Card>

                            <Card.Header style={{ background: 'rgba(4, 23, 105, 0.966)', color: 'white' }}>Ubicaciones</Card.Header>
                            <Card.Body style={{ background: 'rgb(180, 180, 180)' }} >
                                <Container style={{ width: '90%', color: 'black' }}>

                                    <ListGroup>
                                        <Suspense fallback={<LoadSpinner />}>

                                            {
                                                Object.keys(buildings).map((key, index) => (

                                                    !buildings[key as any].hidden ?
                                                    
                                                        <ListGroup.Item action variant="dark" key={index} onClick={() => {
                                                            api.triggerServerCallback('deleteBuilding', key, () => {
                                                                delete api.data.getApp().buildings[key as any]
                                                            });
                                                        }} >
                                                            {buildings[key as any].name}
                                                        </ListGroup.Item>
                                                    :null
                                                  )
                                                )
                                            }

                                        </Suspense>

                                    </ListGroup>

                                </Container>

                            </Card.Body>
                            <Card.Footer style={{ background: 'rgb(218, 214, 213)' }}>
                                <div style={{ display: 'flex', 'justifyContent': 'end' }}>
                                    <Button variant='danger' onClick={() => {
                                        api.triggerServerCallback('createBuilding', false, () => {


                                        });
                                    }}>Añadir nueva ubicación</Button>
                                </div>
                            </Card.Footer>
                        </Card>


                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Buildings;
