import { merge } from "merge-anything";
import { FormEvent } from "react";
import events from "./events";
import proxyHandler from "./proxy";

interface User { displayName: string, range: number }
let user: User

interface Person{
    dni: number,
    Name: string,
    vip: boolean,

}

interface visitor {
    joinDate: number,
    exitDate: number,
    reason: string,
    building: string,
    person: Person,
    comment: string,
    dni: number,
    agent: string

}


interface runningVisit {
    id: number,
}

interface App {
    
    persons: {[id:string]: Person},
    buildings: {[id:number]: {name:string, hidden?:boolean}},
    visitors: {[id:number]:visitor},
    runningVisit: {[id:number]: runningVisit}

}

interface appIndex extends App {
    
    [id:string]: any

}

let onUpdateHandlers:Array<CallableFunction> = [];

let appData:appIndex = new Proxy({buildings:{}, persons: {}, visitors: {}, runningVisit: {}}, proxyHandler<App>([], () => {
    onUpdateHandlers.forEach((cb) => {
        cb()
    }); 
}))
let lastUpdate:number = -1;
let pauseSync = false;
setInterval(() => {
    if (pauseSync) return;
    
    api.triggerServerCallback('syncData', lastUpdate, (data) => {

       if (data) {
        lastUpdate =  data.lastUpdate;
        for (let index in data) {
            if (typeof (appData[index]) === 'object') {
                appData[index] = data[index]
                    
            } else {
                appData[index] = data[index];
            }
        }

       }
        
    });
    
}, 3000);

let api = {
    ...events,

    UserData(cb: (userData: User) => void) {

        if (!user) {
            events.triggerServerCallback('getUser', undefined, (data) => {

                user = data;

                cb(user);

            });
        } else {
            cb(user);
        }
    },

    scripting: {
        parseForm(event: FormEvent<HTMLFormElement>) {
            event.preventDefault();

            let target = event.target as typeof event.target & {
                
                reset: CallableFunction,

                [id:string]: {value:any} | any

            }

            return target;

        },

        pauseSync (val:boolean){
            pauseSync = val
        }
    },

    data: {
        getApp(){
            return appData
        },

        onUpdate(cb:()=>void){
            onUpdateHandlers.push(cb);
        } 
    }

}

export { api, type User, type visitor }

