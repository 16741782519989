import Spinner from 'react-bootstrap/Spinner';

function LoadSpinner() {
  return (
    <>
    <Spinner animation="border" variant="primary" />
    </>
    
  );
}

export default LoadSpinner;