import { Container, Row, Col, Card, Button, Table, ListGroup } from 'react-bootstrap'
import  { Suspense, useEffect, useState } from 'react';
import NavSystem from '../components/nav';
import { api, visitor } from '../api/api';
import LoadSpinner from '../components/spiner';
let interval:any
function RunningHistory() {

    let [visits, setVisits] = useState([] as Array<{data:visitor, current: {id: number}}>)

    
    useEffect(() => {

        api.triggerServerCallback('getRunningVisits', false, (data) => {
            setVisits(data)
        });

        interval = setInterval(() => {
            api.triggerServerCallback('getRunningVisits', false, (data) => {
                setVisits(data)
            });
        }, 3000);

        return () => {
            clearInterval(interval);
        }

    }, []);



    return (
        <>

            <NavSystem />

            <Container className='bodyContainer'>
                <Row style={{ justifyContent: 'center', marginTop: '7%', }}>
                    <Col sm={12} lg={12} >

                        <Card>

                            <Card.Header style={{ background: 'rgba(4, 23, 105, 0.966)', color: 'white' }}>Visitas en progreso</Card.Header>
                            <Card.Body style={{ background: 'rgb(180, 180, 180)' }} >
                                <Container style={{ width: '90%', color: 'black' }}>


                                    <Table striped="columns">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Nombre</th>
                                                <th>DNI/ID</th>
                                                <th>Inicio</th>
                                                <th>Agente</th>
                                                <th>Razón/Motivo</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <Suspense fallback={<LoadSpinner />}>

                                                {
                                                    visits.map((currentVisit, index) => (

                                                        <tr key={index}>
                                                            <td>{currentVisit.current.id}</td>
                                                            <td>{currentVisit.data.person.Name}</td>
                                                            <td>{currentVisit.data.person.dni}</td>
                                                            <td>{new Date(currentVisit.data.joinDate).toLocaleString('es-PE', { timeZone: 'America/Lima' })}</td>
                                                            <td>{currentVisit.data.agent}</td>
                                                            <td>{currentVisit.data.reason}</td>
                                                            <td><Button variant='warning' onClick={() => {
                                                                api.triggerServerCallback('endVisit', {dni: currentVisit.data.dni, id: currentVisit.current.id}, () => {
                                                                    api.triggerServerCallback('getRunningVisits', false, (data) => {
                                                                        setVisits(data)                            
                                                                    }); 
                                                                })
                                                            }}>Finalizar</Button></td>
                                                        </tr>
                                                    )
                                                    )
                                                }

                                            </Suspense>



                                        </tbody>
                                    </Table>



                                </Container>

                            </Card.Body>
                            <Card.Footer style={{ background: 'rgb(218, 214, 213)' }}>
                                <div style={{ display: 'flex', 'justifyContent': 'end' }}>

                                    <Button variant='success' onClick={() => {
                                        api.triggerServerCallback('getRunningVisits', false, (data) => {
                                            setVisits(data)
                                            
                                        });
                                    }}>Actualizar</Button>

                                </div>


                            </Card.Footer>
                        </Card>


                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default RunningHistory;
