import { Container, Row, Col, Card, Button, Table } from 'react-bootstrap'
import { Suspense, useEffect, useState } from 'react';
import NavSystem from '../components/nav';
import { api } from '../api/api';
import LoadSpinner from '../components/spiner';
import { CiSettings } from "react-icons/ci";

interface Person {
    dni: number,
    Name: string,
    vip: boolean,

}
function Vip() {

    let [visits, setVisits] = useState({} as { [id: string]: Person })


    useEffect(() => {

        api.triggerServerCallback('getVipUsers', false, (data) => {
            setVisits(data)
        });


    }, []);


    return (
        <>

            <NavSystem />

            <Container className='bodyContainer'>
                <Row style={{ justifyContent: 'center', marginTop: '7%', }}>
                    <Col sm={12} lg={12} >

                        <Card>

                            <Card.Header style={{ background: 'rgba(4, 23, 105, 0.966)', color: 'white' }}>Usuarios</Card.Header>
                            <Card.Body style={{ background: 'rgb(180, 180, 180)' }} >
                                <Container style={{ width: '90%', color: 'black' }}>


                                    <Table striped="columns">
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>DNI</th>
                                                <th>V.I.P</th>
                                                <th>Tools</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <Suspense fallback={<LoadSpinner />}>

                                                {
                                                    Object.keys(visits).map((key, index) => (

                                                        <tr key={index}>

                                                            <td>{visits[key].Name}</td>
                                                            <td>{visits[key].dni}</td>
                                                            <td>{visits[key].vip ? 'Usuario Vip' : 'Normal'} <CiSettings /> </td>
                                                            <td>
                                                                <Button variant='primary' onClick={() => {
                                                                    api.triggerServerCallback('tootleVip', { id: key }, () => {
                                                                        api.triggerServerCallback('getVipUsers', false, (data) => {
                                                                            setVisits(data)
                                                                        });
                                                                    });
                                                                }} >Quitar Vip</Button>
                                                            </td>



                                                        </tr>
                                                    )
                                                    )
                                                }

                                            </Suspense>



                                        </tbody>
                                    </Table>



                                </Container>

                            </Card.Body>
                            <Card.Footer style={{ background: 'rgb(218, 214, 213)' }}>
                                <Row>
                                    <Col>
                                    </Col>
                                    <Col>
                                        <div style={{ display: 'flex', 'justifyContent': 'end' }}>

                                            <Button variant='danger' onClick={() => {
                                                api.triggerServerCallback('setName', false, () => {
                                                    api.triggerServerCallback('getVipUsers', false, (data) => {
                                                        setVisits(data)
                                                    });
                                                });
                                            }}>Corregir nombre</Button>



                                        </div>
                                    </Col>

                                    <Col>

                                        <div style={{ display: 'flex', 'justifyContent': 'end' }}>

                                            <Button variant='success' onClick={() => {
                                                api.triggerServerCallback('setVip', false, () => {
                                                    api.triggerServerCallback('getVipUsers', false, (data) => {
                                                        setVisits(data)
                                                    });
                                                });
                                            }}>Añadir VIP / Nuevo usuario</Button>



                                        </div>

                                    </Col>


                                </Row>


                            </Card.Footer>
                        </Card>


                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Vip;
